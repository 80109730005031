// @flow
import React from 'react';
import _ from 'lodash/fp';
import { useSelector, useDispatch } from '@graphite/use-redux';
import styled from '@emotion/styled';
import { css, CacheProvider } from '@emotion/core';
import { PopupWhite, Portal, Box } from '@graphite/uneon';
import { getCurrentSiteId, getCurrentUserId, getPresets } from '@graphite/selectors';

import createCache from '@emotion/cache';
import weakMemoize from '@emotion/weak-memoize';

import { getShownAddWidget, getCurrentDevice } from 'Editor/selectors/editor';
import { updateEditor } from 'Editor/ducks/editor';
import useDefaultDevice from 'Editor/libs/use-default-device';

import WidgetPanel from './WidgetPanel';

type TProps = $ReadOnly<{||}>;

const excludeList = ['project', 'site', 'page', 'block', 'col', 'icon'];

const PopupWhiteWrapped = styled(PopupWhite)`
	${({ isHidden }) =>
		isHidden
			? css`
					transition: left 0.1s ease-out 0.15s, opacity 0.15s ease-out 0s !important;
					opacity: 0 !important;
					left: -100% !important;
			  `
			: ''}
`;

const addWidgetPanelSx = {
	position: 'fixed',
	top: 0,
	left: 0,
};

const memoizedCreateCacheWithContainer = weakMemoize(
	container => container && createCache({ container }),
);

function WidgetAdd() {
	const dispatch = useDispatch();
	const siteId = useSelector(getCurrentSiteId);
	const userId = useSelector(getCurrentUserId);
	const isShowAddWidget = useSelector(getShownAddWidget);
	const currentDevice = useSelector(getCurrentDevice);
	const isDefaultDevice = useDefaultDevice() === currentDevice;

	const widgetPresets = getPresets({
		// ToDo: по хорошему siteId и userId надо ловить из пропсов
		// и они должны быть обязательными
		userId: userId || '',
		scope: 'site',
		scopeId: siteId || '',
	});

	const availableWidgetPresets = _.filter(
		({ kind }) => !excludeList.includes(kind),
		widgetPresets,
	);

	const hidePanel = React.useCallback(
		() => dispatch(updateEditor({ isShowAddWidget: false })),
		[dispatch],
	);

	const panelRef = React.useRef();

	return (
		<CacheProvider value={memoizedCreateCacheWithContainer(document.head)}>
			<Portal>
				<Box sx={addWidgetPanelSx} ref={panelRef} />
			</Portal>
			<PopupWhiteWrapped
				anchorEl={panelRef}
				isOpen={isShowAddWidget && isDefaultDevice}
				onClose={hidePanel}
				offsetTop={54}
				mutex="topleftcorner"
				isFixed
			>
				{isShowAddWidget && isDefaultDevice && (
					<WidgetPanel widgetPresets={availableWidgetPresets} />
				)}
			</PopupWhiteWrapped>
		</CacheProvider>
	);
}

export default React.memo<TProps>(WidgetAdd);
