// @flow

import React from 'react';
import _ from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Box, Text } from '@graphite/uneon';

import logger from 'libs/logger';
import Widget from './Widget';
import withGridDrag from './libs/withGridDrag';
import withAbsoluteDrag from './libs/withAbsoluteDrag';
import type { TWidgetsProps } from './constants/types';

const WidgetGrid = withGridDrag(Widget);
const WidgetAbsolute = withAbsoluteDrag(Widget);

const titleSx = {
	marginBottom: '24px',
	cursor: 'default',
};

const tabSx = {
	padding: '12px 0 24px',
	justifyContent: 'left',
	flexShrink: 0,
};

const widgetsSx = {
	position: 'relative',
	margin: '0 -24px 18px',
};

const btnStyle = {
	flexGrow: 0,
};

const WidgetPanel = (props: TWidgetsProps) => {
	const { t } = useTranslation();
	const { widgetPresets } = props;
	const [activeTab, setActiveTab] = React.useState('grid');

	React.useEffect(() => {
		logger.info('openAddWidgetPanel');
	}, []);

	const buttonProps = React.useMemo(
		() => [
			{ name: 'grid', label: t('Grid'), style: btnStyle },
			{ name: 'absolute', label: t('Absolute'), style: btnStyle },
		],
		[t],
	);

	const clickBound = React.useCallback(
		(e, name) => {
			if (typeof name === 'string') {
				setActiveTab(name);
			}
		},
		[setActiveTab],
	);

	const clickForLogging = React.useCallback(() => {
		logger.info('clickOnAddWidgetPanel');
	}, []);

	return (
		<>
			<Text variant="title4" color="text.primaryalt" sx={titleSx}>
				{t('Add')}
			</Text>
			<Text variant="headlinesm" color="text.primaryalt">
				{t('Position')}
			</Text>
			<ButtonGroup
				behavior="radio"
				active={activeTab}
				buttons={buttonProps}
				variant="normal"
				shape="rounded"
				size="sm"
				colors="accentghost"
				activeColors="accent"
				onClick={clickBound}
				sx={tabSx}
			/>
			<Box sx={widgetsSx} onClick={clickForLogging}>
				{widgetPresets.map(preset => {
					if (typeof preset.kind !== 'string') return null;

					if (activeTab === 'grid')
						return (
							<WidgetGrid
								key={preset._id}
								protoId={preset._id}
								title={_.capitalize(preset.kind)}
								icon={`widget-${preset.kind || ''}`}
							/>
						);

					return (
						<WidgetAbsolute
							key={preset._id}
							protoId={preset._id}
							kind={preset.kind}
							title={_.capitalize(preset.kind)}
							icon={`widget-${preset.kind || ''}`}
						/>
					);
				})}
			</Box>
		</>
	);
};

export default React.memo<TWidgetsProps>(WidgetPanel);
