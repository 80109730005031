// @flow

import reSelect from 'libs/re-select';
import type {
	TStateEditor,
	TCurrentWidget,
	TEditor,
	TEditorLang,
	TGridBreakpointName,
	TWidgets,
	TPublicationStatus,
} from '@graphite/types';

type TGetEditor = TStateEditor => TEditor;
export const getEditor: TGetEditor = reSelect(
	state => state.editor,
	editor => editor,
)(() => 'editor@getEditor');

type TGetCurrentWidget = TStateEditor => ?TCurrentWidget;
export const getCurrentWidget: TGetCurrentWidget = reSelect(
	state => state.editor.currentWidget,
	currentWidget => currentWidget,
)(() => 'editor@getCurrentWidget');

type TGetWidgetsBuffer = TStateEditor => ?TWidgets;
export const getWidgetsBuffer: TGetWidgetsBuffer = reSelect(
	state => state.editor.widgetsBuffer,
	widgetsBuffer => widgetsBuffer,
)(() => 'editor@getWidgetBuffer');

type TGetCurrentDevice = TStateEditor => TGridBreakpointName;
export const getCurrentDevice: TGetCurrentDevice = reSelect(
	state => state.editor.currentDevice,
	currentDevice => currentDevice,
)(() => 'editor@getCurrentDevice');

export const getPublishStatus = reSelect<
	TStateEditor,
	TPublicationStatus,
	TPublicationStatus,
>(
	state => (state.publish && state.publish.status) || 'unpublished',
	status => status,
)(() => 'editor@getPublishStatus');

type TGetGridHighlight = TStateEditor => boolean;
export const getGridHighlight: TGetGridHighlight = reSelect(
	state => state.editor.currentGridHighlight,
	currentGridHighlight => currentGridHighlight,
)(() => 'editor@getGridHighlight');

type TGetLanguage = TStateEditor => TEditorLang;
export const getLanguage: TGetLanguage = reSelect(
	state => state.editor.language,
	language => language,
)(() => 'editor@getLanguage');

type TGetShownAddWidget = TStateEditor => boolean;
export const getShownAddWidget: TGetShownAddWidget = reSelect(
	state => state.editor.isShowAddWidget,
	isShowAddWidget => isShowAddWidget,
)(() => 'editor@getShownAddWidget');

export default {};
