// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'emotion-theming';
import { themes, Portal, Flex, Box, Text } from '@graphite/uneon';

import { defaultGridspec } from '@graphite/constants';
import { useDispatch } from '@graphite/use-redux';
import type {
	TStateEditor,
	TId,
	TWidget,
	TWidgets,
	TSpecsGrid,
	TSpecsWidget,
	TSpecsColor,
	TSpecsEffect,
	TSpecsGridBreakpoint,
	TGridBreakpointName,
	TSpecsGridBreakpoints,
} from '@graphite/types';
import {
	getWidgets,
	getWidget,
	getCurrentPageId,
	getCurrentSiteGridSpec,
	getCurrentSiteWidgetSpec,
	getCurrentSiteColorSpec,
	getCurrentSiteEffectSpec,
	getCurrentSiteBreakpoints,
} from '@graphite/selectors';
import { Global, css } from '@emotion/core';
import { getCurrentWidget, getCurrentDevice } from 'Editor/selectors/editor';
import Widget from 'Widget';
import { DNDProvider } from 'Widget/libs/dnd/dndContext';
import getBreakpointWidth from 'libs/get-breakpoint-width';
import Poper from 'Widget/Poper';

import Loader from '../../Loader';
import SymbioteProvider from '../libs/with-symbiote/Provider';
import Frame from '../Frame';
import FrameProvider from '../Frame/Provider';
import {
	wrapperSx,
	overlayOpenSx,
	overlayClosedSx,
	grabStyle,
	grabbingStyle,
	resizeStyle,
	resizeLeftSx,
	resizeLeftActiveSx,
	resizeRightSx,
	resizeRightActiveSx,
} from './constants/styles';
import useControls from './libs/use-controls';
import AbsoluteProvider from './AbsoluteProvider';
import WidgetAdd from './WidgetAdd';

const globalStyle = css`
	body {
		overflow: hidden;
	}
`;

type TProps = $ReadOnly<{||}>;

const getOverlayStyle = (pageState, isScroll) => {
	if (pageState === 'scroll') {
		return isScroll ? grabbingStyle : grabStyle;
	}
	if (pageState === 'resize') {
		return resizeStyle;
	}
	return null;
};

const Page = () => {
	const dispatch = useDispatch();
	const pageId: ?TId = useSelector(getCurrentPageId);
	const currentDevice: TGridBreakpointName = useSelector(getCurrentDevice);
	const currentWidget = useSelector(getCurrentWidget);
	const widgets: TWidgets = useSelector(getWidgets);
	const page: ?TWidget = useSelector((state: TStateEditor) =>
		getWidget(state, { id: pageId || '' }),
	);
	const gridspec: ?TSpecsGrid = useSelector(getCurrentSiteGridSpec);
	const widgetspec: ?TSpecsWidget = useSelector(getCurrentSiteWidgetSpec);
	const colorspec: ?TSpecsColor = useSelector(getCurrentSiteColorSpec);
	const effectspec: ?TSpecsEffect = useSelector(getCurrentSiteEffectSpec);
	const breakpoints: ?TSpecsGridBreakpoints = useSelector(getCurrentSiteBreakpoints);
	const breakpoint: ?TSpecsGridBreakpoint = breakpoints?.[currentDevice];
	const initialWidth = breakpoint ? getBreakpointWidth(breakpoint) : 0;
	const [width, setWidth] = React.useState<number>(initialWidth);
	const [scale] = React.useState<number>(1);
	const ref = React.useRef<mixed>();

	const {
		pageState,
		isScroll,
		isResizeLeft,
		mouseDown,
		mouseDownResizes,
		mouseUp,
		preventMenu,
		mouseMove,
		ownDevice,
	} = useControls({
		gridspec: gridspec || defaultGridspec,
		currentDevice,
		ref,
		initialWidth,
		dispatch,
		setWidth,
	});

	const widgetChain = React.useMemo(() => [page ? page._id : ''], [page]);

	const isLoadWidgets: boolean =
		!page?.children?.length ||
		!!Object.keys(widgets).find((key: TId): boolean => !!page?.children?.[key]);

	React.useEffect(() => {
		// Если выход из редактирования виджета, то надо забрать фокус в главное окно
		if (!currentWidget) {
			// Если не забрать фокус из ифрейма, то он продолжит захватывать события клавиш
			// Тогда перестают работать хоткеи, навсегда
			window.document.body.focus();
		}
	}, [currentWidget]);

	if (
		!page ||
		!breakpoint ||
		!isLoadWidgets ||
		!widgetspec ||
		!colorspec ||
		!gridspec ||
		!effectspec
	)
		return <Loader />;

	const overlayStyle = getOverlayStyle(pageState, isScroll);
	const isResize = pageState === 'resize';

	return (
		<>
			<Global styles={globalStyle} />
			<Portal>
				<Box
					sx={pageState === 'edit' ? overlayClosedSx : overlayOpenSx}
					style={overlayStyle}
					onMouseDown={mouseDown}
					onContextMenu={preventMenu}
					onMouseMove={pageState !== 'edit' ? mouseMove : null}
					onMouseUp={mouseUp}
				/>
			</Portal>
			<Box
				sx={wrapperSx}
				ref={ref}
				onMouseDown={mouseDown}
				onMouseMove={pageState !== 'edit' ? mouseMove : null}
				onMouseUp={mouseUp}
				id="frame-wrapper"
			>
				<FrameProvider>
					<Flex
						sx={isResize && isResizeLeft ? resizeLeftActiveSx : resizeLeftSx}
						onMouseDown={mouseDownResizes.left}
					/>
					<Flex
						sx={
							isResize && !isResizeLeft
								? resizeRightActiveSx
								: resizeRightSx
						}
						onMouseDown={mouseDownResizes.right}
					/>
					<ThemeProvider theme={themes.light}>
						<SymbioteProvider currentWidget={currentWidget}>
							<Frame width={width} scale={scale} ref={ref}>
								<DNDProvider dispatch={dispatch} widgets={widgets}>
									<AbsoluteProvider
										currentDevice={ownDevice}
										widgets={widgets}
										dispatch={dispatch}
										widgetChain={widgetChain}
										widgetspec={widgetspec}
										colorspec={colorspec}
										gridspec={gridspec}
										effectspec={effectspec}
									>
										<WidgetAdd />
										<Widget
											id={page._id}
											containerId={null}
											instanceId={null}
											widgetChain={widgetChain}
											widgetspec={widgetspec}
											colorspec={colorspec}
											gridspec={gridspec}
											effectspec={effectspec}
										/>
									</AbsoluteProvider>
								</DNDProvider>
							</Frame>
						</SymbioteProvider>
					</ThemeProvider>
				</FrameProvider>
				{isResize && (
					<Poper>
						<Text variant="captionlg" color="spec.lightblue10">
							{`${width}px`}
						</Text>
					</Poper>
				)}
			</Box>
		</>
	);
};

export default React.memo<TProps>(Page);
