// @flow

import React from 'react';
import { ToolbarBtn, PopupWhite } from '@graphite/uneon';
import type {
	TWidget,
	TWidgetDiff,
	TPositionValue,
	TGridBreakpointName,
} from '@graphite/types';

import Position from './Position';

type TProps = $ReadOnly<{|
	setOpenedPanel: (?(((?string) => ?string) | string)) => void,
	openedPanel: ?string,
	unit: number,
	position: TPositionValue,
	data: TWidget,
	currentDevice: TGridBreakpointName,
	save: TWidgetDiff => void,
	currentRef: {| current: ?HTMLDivElement |},
|}>;

const ToolbarLayoutButton = ({
	openedPanel,
	setOpenedPanel,
	unit,
	save,
	data,
	position,
	currentDevice,
	currentRef,
}: TProps) => {
	const btnEl = React.useRef(null);
	const handleToogleOpen = React.useCallback(
		e => {
			e.preventDefault();

			setOpenedPanel(openedPanel => (openedPanel === 'layout' ? null : 'layout'));
		},
		[setOpenedPanel],
	);
	const disableActive = React.useCallback(
		(e: MouseEvent) => {
			e.preventDefault();

			setOpenedPanel(null);
		},
		[setOpenedPanel],
	);

	const isActive = openedPanel === 'layout';

	return (
		<>
			<ToolbarBtn
				onMouseDown={handleToogleOpen}
				iconName="layout"
				ref={btnEl}
				isActive={isActive}
			/>
			<PopupWhite
				isOpen={isActive}
				onClose={disableActive}
				anchorEl={btnEl}
				offsetTop={10}
				isFixed
				mutex="settings"
			>
				{isActive && (
					<>
						<Position
							data={data}
							position={position}
							save={save}
							unit={unit}
							currentRef={currentRef}
							currentDevice={currentDevice}
						/>
					</>
				)}
			</PopupWhite>
		</>
	);
};

export default React.memo<TProps>(ToolbarLayoutButton);
