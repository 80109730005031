// @flow
import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from '@graphite/use-redux';
import type { RouterHistory } from 'react-router-dom';
import { Flex } from '@graphite/uneon';
import type { TId, TEditorLang } from '@graphite/types';
import logger from 'libs/logger';
import {
	getCurrentSiteId,
	getWidget,
	getCurrentProjectId,
	getCurrentPageId,
} from '@graphite/selectors';
import {
	getShownAddWidget,
	getLanguage,
	getGridHighlight,
	getCurrentDevice,
} from 'Editor/selectors/editor';
import { updateEditor } from 'Editor/ducks/editor';
import Preview from './Preview';
import Builder from './Builder';
import { HEIGHT } from './constants';

type TProps = $ReadOnly<{|
	history: RouterHistory,
|}>;

const headerFlexSx = {
	position: 'fixed',
	top: '0',
	width: '100%',
	height: `${HEIGHT}px`,
	padding: '0 12px',
	backgroundColor: 'bg.primaryminus',
	userSelect: 'none',
	zIndex: 150,
};

function TopBar({ history }: TProps) {
	const dispatch = useDispatch();
	const isShowAddWidget = useSelector(getShownAddWidget);
	const projectId: ?TId = useSelector(getCurrentProjectId);
	const pageId: ?TId = useSelector(getCurrentPageId);
	const language: TEditorLang = useSelector(getLanguage);
	const currentGridHighlight: boolean = useSelector(getGridHighlight);
	const currentDevice = useSelector(getCurrentDevice);

	const siteId: ?TId = useSelector(getCurrentSiteId);
	const site = useSelector(state => (siteId ? getWidget(state, { id: siteId }) : null));
	const publishUrl: string =
		(site?.domain ? `http://${site?.domain}` : site?.url) || '';

	const url = `/project/${projectId || ''}/site/${siteId || ''}/page/${pageId || ''}`;

	const panelRef = React.useRef();

	const showAddWidgetPanel = React.useCallback(() => {
		dispatch(updateEditor({ isShowAddWidget: !isShowAddWidget }));
	}, [dispatch, isShowAddWidget]);

	const goToBuider = React.useCallback(() => {
		logger.info('editorVisit');
		history.push(url);
	}, [history, url]);

	const goToPreview = React.useCallback(() => {
		logger.info('openPreview');
		history.push(`${url}/preview`);
	}, [history, url]);

	const goToDashboard = React.useCallback(() => history.push(''), [history]);

	if (!(projectId && siteId && pageId)) {
		return null; // если этого всего нет, то хули мы тут забыли?
	}

	return (
		<Flex as="header" sx={headerFlexSx} ref={panelRef}>
			<Switch>
				<Route path="/project/:projectId/site/:siteId/page/:pageId/preview">
					<Preview publishUrl={publishUrl} goToBuider={goToBuider} />
				</Route>
				<Route path="">
					<Builder
						publishUrl={publishUrl}
						panelRef={panelRef}
						goToPreview={goToPreview}
						goToDashboard={goToDashboard}
						showAddWidgetPanel={showAddWidgetPanel}
						language={language}
						currentGridHighlight={currentGridHighlight}
						siteId={siteId}
						currentDevice={currentDevice}
					/>
				</Route>
			</Switch>
		</Flex>
	);
}

export default withRouter(React.memo<TProps>(TopBar));
