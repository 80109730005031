// @flow
import _ from 'lodash/fp';
import emptyObject from 'empty/object';
import getRadius from '@graphite/get-radius';
import getBorder from '@graphite/get-border';
import getColor from '@graphite/get-color';
import type {
	TSx,
	TId,
	TWidgetKind,
	TEntityChildren,
	TSpecsGrid,
	TSpecsEffect,
	TSpecsWidget,
	TSpecsColor,
	TDesignBreakpoint,
	TDesign,
	TGridBreakpointName,
	TSpecsGridBreakpoint,
	TBackgroundValues,
	TDesignCommonEffect,
	TBgImage,
	TGradient,
	TGradientStop,
	TGradientStops,
	TGradientLinear,
	TGradientRadial,
	TShadowValues,
	TPositioned,
	TOffsetDevice,
	TSize,
	TSizesBreakpoint,
	TWidgets,
	TPositionValue,
	TWidget,
	TOrder,
	TWidgetBox,
	TWidgetBoxBreakpoint,
	TOrderDevice,
	TPublishedState,
	TEntityScope,
} from '@graphite/types';
import {
	gridBreakpointsNames,
	minHeight,
	minWidth,
	presetWidgets,
	listForFontsLib,
} from '@graphite/constants';

import getUnit from '../libs/get-unit';
import reSelect from '../libs/re-select';
import { closestDeviceWithKey } from '../grid';
import { getActiveBreakpointNames } from '../specs';
import { maxCombineSx } from '../max-combine-sx';

// FixMe: ...
const logger = console;

type TStackDirection = 'vertical' | 'horizontal';

type TGetWidgets = TPublishedState => TWidgets;
export const getWidgets: TGetWidgets = reSelect(
	({ widgets }) => widgets,
	widgets => widgets,
)(() => 'widgets@getWidgets');

type TGetWidgetsByKind = (
	TPublishedState,
	$ReadOnly<{| [string]: ?string |}>,
) => TWidgets;
export const getWidgetsByKind: TGetWidgetsByKind = reSelect<
	TPublishedState,
	$ReadOnly<{ [string]: ?string }>,
	TWidgets,
	string,
	TWidgets,
>(
	getWidgets,
	(state, parametrs) => JSON.stringify(parametrs),
	(widgets, parametrs) => _.pickBy(_.matches(JSON.parse(parametrs)), widgets),
)((state, parametrs) => `widgets@getWidgetsByKind-${JSON.stringify(parametrs)}`);

type TGetWidget = (TPublishedState, { id: TId }) => ?TWidget;
export const getWidget: TGetWidget = reSelect(
	getWidgets,
	(state, { id }: { id: TId }) => id,
	(widgets, id) => widgets[id],
)((state, { id }) => `widgets@getWidget-${id}`);

export const getBoxWidth = reSelect<TWidget, TWidget, $ReadOnlyArray<number>>(
	box => box,
	() => [1, 1, 1],
)(box => `widgets@getBoxWidth-${box._id}`);

// Возвращает массив с массивами вида [изначальный Id, актуальный Id]
// Ордер берётся для текущего девайса или ближайшего существующего
export const getWidgetIdsRelations = reSelect<
	$ReadOnly<{
		_id: TId,
		children: ?TEntityChildren,
		order: ?TOrder,
		currentDevice: ?TGridBreakpointName,
	}>,
	TEntityChildren,
	TOrderDevice,
	$ReadOnlyArray<[TId, TId]>,
>(
	({ children }) => children || emptyObject,
	({ _id, order, currentDevice }) =>
		closestDeviceWithKey(order, { currentDevice, key: `order-${_id}` }),
	(children, order) =>
		_.toPairs(children)
			.filter(([, id]) => id)
			.sort(([a], [b]) => order[a] - order[b]),
)(({ _id }) => `widget@getWidgetIdsRelationsMap-${_id}`);

// Оставляет от предыдущего только актуальные
export type TRefTrueIdPair = $ReadOnly<{| refId: TId, trueId: TId |}>;
export const getTrueWidgetIds = reSelect<
	$ReadOnly<{
		_id: TId,
		children: ?TEntityChildren,
		order: ?TOrder,
		currentDevice: ?TGridBreakpointName,
	}>,
	$ReadOnlyArray<[TId, TId]>,
	$ReadOnlyArray<{ refId: TId, trueId: TId }>,
>(getWidgetIdsRelations, relationArr =>
	relationArr.map(([refId, trueId]) => ({ refId, trueId })),
)(({ _id }) => {
	return `widget@getTrueWidgetIds-${_id}`;
});

// Возвращает массив с массивами вида [изначальный Id, актуальный Id]
// Ордер берётся для текущего девайса или ближайшего существующего
export const getIdsRelations = reSelect<
	$ReadOnly<{
		_id: TId,
		children: ?TEntityChildren,
		order: ?TOrder,
		currentDevice: TGridBreakpointName,
	}>,
	TEntityChildren,
	TOrderDevice,
	$ReadOnlyArray<[TId, TId]>,
>(
	({ children }) => children || (emptyObject: TEntityChildren),
	({ _id, order, currentDevice }) =>
		closestDeviceWithKey(order, { currentDevice, key: `order-${_id}` }),
	(children, order) =>
		_.flow([_.toPairs, _.filter(([, id]) => id), _.sortBy(([key]) => order[key])])(
			children,
		),
)(({ _id }) => {
	return `widget@getWidgetIdsRelationsMap-${_id}`;
});

// Оставляет от предыдущего только актуальные
export const getTrueIds = reSelect<
	$ReadOnly<{
		_id: TId,
		children: ?TEntityChildren,
		order: ?TOrder,
		currentDevice: TGridBreakpointName,
	}>,
	$ReadOnlyArray<[TId, TId]>,
	$ReadOnlyArray<TId>,
>(
	getWidgetIdsRelations,
	_.map(([, trueId]) => trueId),
)(({ _id }) => {
	return `widget@getTrueIds-${_id}`;
});

export type TGetTrueWidgets = (
	{ widgets: TWidgets },
	{ id: TId, currentDevice: TGridBreakpointName },
) => TWidgets;
export const getTrueWidgets: TGetTrueWidgets = _.flow(
	cached => (
		{ widgets }: { widgets: TWidgets },
		{ id, currentDevice }: { id: TId, currentDevice: TGridBreakpointName },
	) => {
		const data = widgets[id];
		// eslint-disable-next-line no-param-reassign
		const cache = id in cached ? cached[id] : (cached[id] = {});
		const ids = getTrueIds({
			_id: data._id,
			children: data.children,
			order: data.order,
			currentDevice,
		});
		if (cache.key !== ids) {
			cache.key = ids;
			cache.value = _.pickBy(({ _id }) => ids.includes(_id), { ...widgets });
		}
		return cache.value;
	},
)({});

// Свопает местами актуальный и изначальный id-шники
export const getWidgetSwappedIds = reSelect<
	$ReadOnly<{
		_id: TId,
		children: ?TEntityChildren,
		order: ?TOrder,
		currentDevice: ?TGridBreakpointName,
	}>,
	$ReadOnlyArray<[TId, TId]>,
	$ReadOnly<{ [TId]: TId }>,
>(getWidgetIdsRelations, relationArr =>
	relationArr.reduce((acc, currentIdPair) => {
		// Свопаем id-шники местами)
		const [data, render] = currentIdPair;
		acc[render] = data;
		return acc;
	}, {}),
)(({ _id }) => {
	return `widget@getWidgetSwappedIds-${_id}`;
});

export const getListAvailableWidgets = reSelect<
	{ userId: TId, scope: TEntityScope, scopeId: TId },
	TId,
	TEntityScope,
	TId,
	TWidgets,
>(
	({ userId }) => userId,
	({ scope }) => scope,
	({ scopeId }) => scopeId,
	(userId, scope, scopeId) => {
		const computedWidgets = _.mapValues(
			(widget: TWidget): TWidget => ({
				...widget,
				userId,
				scope,
				scopeId,
			}),
			presetWidgets,
		);

		return _.mapKeys((key: string): TId => computedWidgets[key]._id, computedWidgets);
	},
)(
	({ userId, scope, scopeId }) =>
		`widgets@getPresets-${userId}-${scope}-${scopeId}-${userId}`,
);

export const getPresets = reSelect<
	{ userId: TId, scope: TEntityScope, scopeId: TId },
	TId,
	TEntityScope,
	TId,
	TWidgets,
>(
	({ userId }) => userId,
	({ scope }) => scope,
	({ scopeId }) => scopeId,
	(userId, scope, scopeId) => {
		const computedWidgets = _.mapValues(
			(widget: TWidget): TWidget => ({
				...widget,
				userId,
				scope,
				scopeId,
			}),
			presetWidgets,
		);

		return _.mapKeys((key: string): TId => computedWidgets[key]._id, computedWidgets);
	},
)(
	({ userId, scope, scopeId }) =>
		`widgets@getPresets-${userId}-${scope}-${scopeId}-${userId}`,
);

export const getTrueIdPositions = reSelect<
	$ReadOnlyArray<TRefTrueIdPair>,
	TPositioned,
	$ReadOnlyArray<TRefTrueIdPair>,
	TPositioned,
	TPositioned,
>(
	(trueWidgetIds): $ReadOnlyArray<TRefTrueIdPair> => trueWidgetIds,
	(trueWidgetIds, positions): TPositioned => positions,
	(trueWidgetIds, positions): TPositioned =>
		Object.assign(
			{},
			...trueWidgetIds.map(({ refId, trueId }) => ({
				[trueId]: positions[refId] || null,
			})),
		),
)(
	(trueWidgetIds, positions) =>
		`widget@getTrueIdPositions-${_.keys(positions).join('-')}`,
);

export const getSingleOrder = reSelect<TId, TId, TOrder>(
	id => id,
	id =>
		gridBreakpointsNames.reduce((acc, device) => {
			acc[device] = { [id]: 0 };
			return acc;
		}, {}),
)((id: TId): string => `widget@emptyOrder-${id}`);

export default {};

// Вычисление размеров виджета и его позиции, относительно родителя.
// Для ABS виджетов.
export const getOffset = reSelect<
	{ current: React$ElementRef<*> | null },
	{ current: React$ElementRef<*> | null },
	{ current: React$ElementRef<*> | null },
	{ current: React$ElementRef<*> | null },
	TOffsetDevice,
>(
	(widget): { current: React$ElementRef<*> | null } => widget,
	(widget, parentRef): { current: React$ElementRef<*> | null } => parentRef,
	(widget, parentRef): TOffsetDevice => {
		let offset: TOffsetDevice = { top: 0, left: 0 };

		if (widget && widget.current) {
			const { height, width, top, left } = widget.current.getBoundingClientRect();

			offset = { ...offset, height, width };

			let parent: ?Element = null;
			let parentPosition: string = '';

			if (parentRef && parentRef.current) {
				parent = parentRef.current;
				parentPosition = window.getComputedStyle(parent).position;
			} else {
				// Если вдруг не оказалось parentRef, пройдёмся по родителям виджета.
				// Вряд ли до этого дойдёт.
				parent = widget.current;
				while (parent) {
					if (!parent || parentPosition === 'relative') break;
					parent = parent.parentElement;
					parentPosition = window.getComputedStyle(parent).position;
				}
			}

			if (parent && parentPosition === 'relative') {
				const parentOffset = parent.getBoundingClientRect();
				if (parentOffset) {
					offset = {
						...offset,
						top: top - parentOffset.top,
						left: left - parentOffset.left,
					};
				}
			}
		}

		return offset;
	},
)((widget, parentRef) =>
	['widget@getOffset', !!widget.current, !!parentRef.current].join('-'),
);

export type TAdjustColorspecParams = $ReadOnly<{|
	data: TWidget,
	colorspec: TSpecsColor,
	widgetspec: TSpecsWidget,
	target: TWidgetKind,
|}>;

// Эта функция возвращает объект, передаваемый в свойство `sx`
export type TAdjustColorspecFn = TAdjustColorspecParams => TSpecsColor;

// Стилизация всех виджетов по спекам, с учётом адаптивности для активных девайсов
export const adjustColorspec: TAdjustColorspecFn = reSelect<
	TAdjustColorspecParams,
	?TId,
	?string,
	TSpecsColor,
	TSpecsWidget,
	TWidgetKind,
	TSpecsColor,
>(
	({ data }) => data.designId,
	({ data }) => (data.designId && data.designs?.[data.designId]?.theme) || '',
	({ colorspec }) => colorspec,
	({ widgetspec }) => widgetspec,
	({ target }) => target,
	(designId, theme, colorspec, widgetspec, target) => {
		try {
			if (designId) {
				const activeTheme: string =
					theme ||
					widgetspec[target].find(des => des._id === designId)?.theme ||
					'';
				if (activeTheme && activeTheme !== colorspec.activeTheme) {
					return ({ ...colorspec, activeTheme }: TSpecsColor);
				}
			}
			return colorspec;
		} catch (e) {
			logger.error(e);
			return colorspec;
		}
	},
)(({ data, colorspec, widgetspec, target }) =>
	[
		'widget@adjustColorspec',
		(data.designId && data.designs?.[data.designId]?.theme) || 'null',
		data.designId || 'null',
		colorspec._id,
		widgetspec._id,
		target,
	].join('-'),
);

export type TAdjustGridspecParams = $ReadOnly<{|
	data: TWidget,
	gridspec: TSpecsGrid,
|}>;

// Эта функция возвращает объект, передаваемый в свойство `sx`
export type TAdjustGridspecFn = TAdjustGridspecParams => TSpecsGrid;

// Стилизация всех виджетов по спекам, с учётом адаптивности для активных девайсов
export const adjustGridspec: TAdjustGridspecFn = reSelect<
	TAdjustGridspecParams,
	?TSpecsGrid,
	TSpecsGrid,
	TSpecsGrid,
>(
	({ data }) => data.gridspec,
	({ gridspec }) => gridspec,
	(dataGridspec, gridspec) => {
		try {
			if (dataGridspec) {
				return (_.set(
					'breakpoints',
					dataGridspec.breakpoints,
					gridspec,
				): TSpecsGrid);
			}
			return gridspec;
		} catch (e) {
			logger.error(e);
			return gridspec;
		}
	},
)(({ data, gridspec }) =>
	[
		'widget@adjustGridspec',
		data._id,
		!!data.gridspec,
		(data.gridspec && data.gridspec._id) || gridspec._id,
	].join('-'),
);

// --------------- SX TERRITORY -----------------//

export type TGetBgImageParams = $ReadOnly<{|
	unit: number,
	image: TBgImage,
|}>;
export type TGetBgImageFn = TGetBgImageParams => string;

export const getBgImage: TGetBgImageFn = reSelect<
	TGetBgImageParams,
	number,
	TBgImage,
	string,
>(
	({ unit }) => unit,
	({ image }) => image,
	(unit, image) => {
		try {
			// https://developer.mozilla.org/en-US/docs/Web/CSS/background
			const baseStr = [
				`url(${image.src})`,
				getUnit(unit, image.x, image.xUnit),
				getUnit(unit, image.y, image.yUnit),
			].join(' ');

			// The <bg-size> value may only be included immediately after <position>,
			// separated with the '/' character, like this: "center/80%".

			if (image.kind === 'fill' || image.kind === 'fit') {
				const size = image.kind === 'fill' ? 'cover' : 'contain';
				return `${baseStr}/${size} no-repeat`;
			}

			const cropStr = [
				baseStr,
				'/',
				getUnit(unit, image.width, image.widthUnit),
				getUnit(unit, image.height, image.heightUnit),
			].join(' ');
			if (image.kind === 'crop') {
				return `${cropStr} no-repeat`;
			}

			return `${cropStr} ${image.repeat}`;
		} catch (e) {
			logger.error(e);
			return 'none';
		}
	},
)(({ unit, image }) =>
	['widget@getBgImage', unit, image.src || 'empty', image.kind].join('-'),
);

export type TGetGradientParams = $ReadOnly<{|
	colorspec: TSpecsColor,
	gradient: TGradient,
|}>;
export type TGetGradientFn = TGetGradientParams => string;

// Стрингифицирует список точек градиента. На вход точки, на выход кусок ЦСС.
// Точки здесь приходят как объект, поэтому включена сортировка по позиции.
// https://www.w3schools.com/css/css3_gradients.asp
// Пример:
//  stops: { a: { id: 'a', at: 57, color: '#fff' }, b: { id: 'b', at: 11, color: '#000' } }
//  return '#000 11%, #fff 57%'
const getStops = (spec: TSpecsColor, stops: TGradientStops): string => {
	const stopsSorted: $ReadOnlyArray<TGradientStop> = _.values(stops).sort(
		(a, b) => a.at - b.at,
	);
	return stopsSorted.map(s => `${getColor(spec, s.color)} ${s.at}%`).join(', ');
};

const getLinearGradient = (spec: TSpecsColor, linear: TGradientLinear): string => {
	return `linear-gradient(${linear.angle}deg, ${getStops(spec, linear.stops)})`;
};

const getRadialGradient = (spec: TSpecsColor, radial: TGradientRadial): string => {
	return [
		'radial-gradient(',
		`${radial.shape} ${radial.size} `,
		`at ${radial.x}% ${radial.y}%, `,
		getStops(spec, radial.stops),
		')',
	].join('');
};

export const getGradient: TGetGradientFn = reSelect<
	TGetGradientParams,
	TSpecsColor,
	TGradient,
	string,
>(
	({ colorspec }) => colorspec,
	({ gradient }) => gradient,
	(colorspec, gradient) => {
		try {
			if (gradient.kind === 'linear') {
				return getLinearGradient(colorspec, gradient);
			}
			if (gradient.kind === 'radial') {
				return getRadialGradient(colorspec, gradient);
			}
		} catch (e) {
			logger.error(e);
		}
		return 'none';
	},
)(({ colorspec, gradient }) =>
	['widget@getGradient', colorspec._id, gradient.kind, gradient.stops.length].join('-'),
);

export type TGetShadowParams = $ReadOnly<{|
	colorspec: TSpecsColor,
	shadow: TShadowValues,
|}>;
export type TGetShadowFn = TGetShadowParams => string;

export const getShadow: TGetShadowFn = reSelect<
	TGetShadowParams,
	TSpecsColor,
	TShadowValues,
	string,
>(
	({ colorspec }) => colorspec,
	({ shadow }) => shadow,
	(colorspec, shadow) => {
		try {
			if (!shadow.length) {
				return 'none';
			}
			return shadow
				.map(({ value }) => {
					return [
						value.kind === 'inner' ? 'inset ' : '',
						[
							`${value.x}px`,
							`${value.y}px`,
							`${value.blur}px`,
							`${value.spread}px`,
							colorspec ? getColor(colorspec, value.color) : '#000',
						].join(' '),
					].join('');
				})
				.join(', ');
		} catch (e) {
			logger.error(e);
			return 'none';
		}
	},
)(({ colorspec, shadow }) =>
	[
		'widget@getShadow',
		colorspec._id,
		shadow.length,
		...shadow.map(s => s.value.id),
	].join('-'),
);

export const getTextShadow: TGetShadowFn = reSelect<
	TGetShadowParams,
	TSpecsColor,
	TShadowValues,
	string,
>(
	({ colorspec }) => colorspec,
	({ shadow }) => shadow,
	(colorspec, shadow) => {
		try {
			if (!shadow.length) {
				return 'none';
			}
			return shadow
				.map(({ value }) => {
					return [
						value.kind === 'inner' ? 'inset ' : '',
						[
							`${value.x}px`,
							`${value.y}px`,
							`${value.blur}px`,
							colorspec ? getColor(colorspec, value.color) : '#000',
						].join(' '),
					].join('');
				})
				.join(', ');
		} catch (e) {
			logger.error(e);
			return 'none';
		}
	},
)(({ colorspec, shadow }) =>
	[
		'widget@getTextShadow',
		colorspec._id,
		shadow.length,
		...shadow.map(s => s.value.id),
	].join('-'),
);

export type TGetBackgroundParams = $ReadOnly<{|
	colorspec: TSpecsColor,
	unit: number,
	background: TBackgroundValues,
|}>;

// Эта функция возвращает строку для CSS свойства background
export type TGetBackgroundFn = TGetBackgroundParams => string;

export const getBackground: TGetBackgroundFn = reSelect<
	TGetBackgroundParams,
	TSpecsColor,
	number,
	TBackgroundValues,
	string,
>(
	({ colorspec }) => colorspec,
	({ unit }) => unit,
	({ background }) => background,
	(colorspec, unit, background) => {
		try {
			if (!background.length) {
				return 'none';
			}
			return background
				.map(bg => {
					if (bg.kind === 'color') {
						const cssColor = getColor(colorspec, bg.value);
						return `linear-gradient(to right, ${cssColor}, ${cssColor})`;
					}
					if (bg.kind === 'gradient') {
						return getGradient({ colorspec, gradient: bg.value });
					}
					if (bg.kind === 'bgimage') {
						return getBgImage({ unit, image: bg.value });
					}
					return 'none';
				})
				.join(', ');
		} catch (e) {
			logger.error(e);
			return 'none';
		}
	},
)(({ colorspec, unit, background }) =>
	['widget@getBackground', colorspec._id, unit, ...background.map(s => s.kind)].join(
		'-',
	),
);

// Возвращает цвета, полученные из колорспеки по данным `source`
export type TGetStateColorSxParams = $ReadOnly<{|
	colorspec: TSpecsColor,
	source: ?TDesignCommonEffect,
	unit: number,
|}>;

export type TGetStateColorSxFn = TGetStateColorSxParams => TSx;

export const getStateColorSx: TGetStateColorSxFn = reSelect<
	TGetStateColorSxParams,
	TSpecsColor,
	?TDesignCommonEffect,
	number,
	TSx,
>(
	({ colorspec }) => colorspec,
	({ source }) => source,
	({ unit }) => unit,
	(colorspec, source, unit) => {
		try {
			if (!source) {
				return (emptyObject: TSx);
			}

			let out = (emptyObject: TSx);

			const { background, border, overlay, shadow: bgShadow } = source.container;
			const { color, shadow: textShadow } = source.text;
			if (background) {
				out = (_.set(
					'background',
					getBackground({ colorspec, unit, background }),
					out,
				): TSx);
			}
			if (bgShadow) {
				out = (_.set(
					'boxShadow',
					getShadow({ colorspec, shadow: bgShadow }),
					out,
				): TSx);
			}
			if (border) {
				out = (_.set('borderColor', getColor(colorspec, border), out): TSx);
			}
			if (color) {
				out = (_.set('color', getColor(colorspec, color), out): TSx);
			}
			if (textShadow) {
				out = (_.set(
					'textShadow',
					getTextShadow({ colorspec, shadow: textShadow }),
					out,
				): TSx);
			}
			if (overlay) {
				out = (_.set(
					':after',
					{
						content: '""',
						position: 'absolute',
						top: 0,
						bottom: 0,
						left: 0,
						right: 0,
						opacity: 1,
						background: getBackground({
							colorspec,
							unit,
							background: overlay,
						}),
					},
					out,
				): TSx);
			}
			return out;
		} catch (e) {
			logger.error(e);
			return (emptyObject: TSx);
		}
	},
)(({ colorspec, source, unit }) =>
	[
		'widget@getColorSx',
		colorspec._id,
		unit,
		source ? _.size(source.container) : 'null',
		source ? _.size(source.text) : 'null',
	].join('-'),
);

export type TGetColorSxParams = $ReadOnly<{|
	breakpoint: TDesignBreakpoint,
	colorspec: TSpecsColor,
	unit?: ?number,
|}>;

// Эта функция возвращает объект, передаваемый в свойство `sx`
export type TGetColorSxFn = TGetColorSxParams => TSx;

// Для стилизации цвета и тени по спеке
export const getColorSx: TGetColorSxFn = reSelect<
	TGetColorSxParams,
	TDesignBreakpoint,
	TSpecsColor,
	number,
	TSx,
>(
	({ breakpoint }) => breakpoint,
	({ colorspec }) => colorspec,
	({ unit }) => unit || 1,
	(breakpoint, colorspec, unit) => {
		try {
			// Подбор цветов для 4 состояний: обычный, ховер, актив, фокус
			// В спеке может не быть любого или всех этих цветов,
			// Если цвета не указаны в спеке то в `colorsSx` они не должны попасть
			let colorsSx = getStateColorSx({
				colorspec,
				source: breakpoint.normal,
				unit: unit || 1,
			});

			// Остальные цвета состояний вкладываются в псевдокласс с таким именем
			['hover', 'focus', 'active'].forEach(state => {
				// Сначала проверить есть ли хоть 1 цвет для этого состояния
				const current = breakpoint[state];
				if (!current) {
					return;
				}

				if (
					current.container.background ||
					current.container.border ||
					current.text.color
				) {
					// Добавляем стили в новый объект, а его уже вкладываем в псевдокласс
					const stateSx = getStateColorSx({
						colorspec,
						source: current,
						unit: unit || 1,
					});
					colorsSx = (_.set(`:${state}`, stateSx, colorsSx): TSx);
				}
			});

			return colorsSx;
		} catch (e) {
			logger.error(e);
			return (emptyObject: TSx);
		}
	},
)(({ breakpoint, colorspec, unit }) =>
	['widget@getColorSx', colorspec._id, unit, _.size(breakpoint)].join('-'),
);

export type TGetAbsBreakpointSxParams = $ReadOnly<{|
	breakpoint: TWidgetBoxBreakpoint,
|}>;

// Эта функция возвращает объект, передаваемый в свойство `sx`
export type TGetAbsBreakpointSxFn = TGetAbsBreakpointSxParams => TSx;

// Для стилизации текста по спеке
export const getAbsBreakpointSx: TGetAbsBreakpointSxFn = reSelect<
	TGetAbsBreakpointSxParams,
	TWidgetBoxBreakpoint,
	TSx,
>(
	({ breakpoint }) => breakpoint,
	breakpoint => {
		try {
			const { offset } = breakpoint;

			// Нефиг тут делать, если для девайса не установлен offset
			// для этого есть дефолтные стили или offset девайса выше
			// да и вообще не должно быть такой ситуации, когда ни одного девайса нет offset
			// если виджет является abs
			if (!offset) return emptyObject;

			const { centerx, centery, left, top, right, bottom, height, width } = offset;
			// Нельзя оставлять это с null, т.к другие media перебьют нежелаемое свойство
			const finalSx = {
				top: top || 'auto',
				bottom: bottom || 'auto',
				left: left || 'auto',
				right: right || 'auto',
				width: width || null,
				height: height || null,
			};

			if (typeof centerx === 'number') {
				// Центр - 50%, отступаем от 50% по X
				finalSx.left = `calc(50% + ${centerx}px)`;
				finalSx.width = `${width}px`;
			} else {
				if (
					typeof height === 'number' &&
					(typeof left !== 'number' || typeof right !== 'number')
				) {
					finalSx.width = `${width}px`;
				}

				if (typeof left === 'number') finalSx.left = `${left}px`;
				if (typeof right === 'number') finalSx.right = `${offset.right}px`;
			}

			if (typeof centery === 'number') {
				// Центр - 50%, отступаем от 50% по Y
				finalSx.top = `calc(50% + ${centery}px)`;
				if (typeof height === 'number') {
					finalSx.height = `${height}px`;
				}
			} else {
				if (
					typeof height === 'number' &&
					(typeof top !== 'number' || typeof bottom !== 'number')
				) {
					finalSx.height = `${height}px`;
				}

				if (typeof top === 'number') finalSx.top = `${top}px`;
				if (typeof bottom === 'number') finalSx.bottom = `${bottom}px`;
			}

			return finalSx;
		} catch (e) {
			logger.error(e);
			return (emptyObject: TSx);
		}
	},
)(({ breakpoint }) => ['widget@getAbsBreakpointSx', _.size(breakpoint)].join('-'));

export type TGetAbsSxParams = $ReadOnly<{|
	data: TWidget,
	gridspec: TSpecsGrid,
|}>;

// Эта функция возвращает объект, передаваемый в свойство `sx`
export type TGetAbsSxFn = TGetAbsSxParams => TSx;

// Стилизация абс виджетов по спекам, с учётом адаптивности для активных девайсов
export const getAbsSx: TGetAbsSxFn = reSelect<
	TGetAbsSxParams,
	?TWidgetBox,
	TId,
	TSpecsGrid,
	TSx,
>(
	({ data }) => data.box,
	({ data }) => data._id,
	({ gridspec }) => gridspec,
	(box, _id, gridspec) => {
		const breakpointNames = getActiveBreakpointNames({ gridspec });

		const finalSx = maxCombineSx({
			sxList: _.map(currentDevice => {
				const breakpoint = closestDeviceWithKey(box, {
					currentDevice,
					key: `abs-${_id}`,
				});

				const breakpointSx = getAbsBreakpointSx({
					breakpoint,
				});

				return breakpointSx;
			}, breakpointNames),
			gridspec,
		});

		return finalSx;
	},
)(({ data, gridspec }) => ['widget@getAbsSx', data._id, gridspec._id].join('-'));

const baseBoxSx = {
	minHeight: `${minHeight}px`,
	minWidth: `${minWidth}px`,
	flexShrink: 0,
};

const alignMap = {
	left: 'flex-start',
	center: 'center',
	justify: 'stretch',
	right: 'flex-end',
};

export type TGetBoxBreakpointSxParams = $ReadOnly<{|
	breakpoint: TWidgetBoxBreakpoint,
	position: TPositionValue,
	direction: TStackDirection,
	unit: number,
|}>;

// Эта функция возвращает объект, передаваемый в свойство `sx`
export type TGetBoxBreakpointSxFn = TGetBoxBreakpointSxParams => TSx;

// Для стилизации контейнера виджета по спеке
export const getBoxBreakpointSx: TGetBoxBreakpointSxFn = reSelect<
	TGetBoxBreakpointSxParams,
	TWidgetBoxBreakpoint,
	TPositionValue,
	TStackDirection,
	number,
	TSx,
>(
	({ breakpoint }) => breakpoint,
	({ position }) => position,
	({ direction }) => direction,
	({ unit }) => unit,
	(breakpoint, position, direction, unit) => {
		const {
			width,
			widthUnit,
			height,
			heightUnit,
			padding = {},
			margin = {},
			align,
		} = breakpoint;

		const customSx = {};

		customSx.display = breakpoint.hidden ? 'none' : 'flex';

		if (breakpoint.flexDirection !== undefined) {
			customSx.flexDirection = breakpoint.flexDirection || 'column';
			customSx.alignItems = breakpoint.alignItems || 'stretch';
			customSx.justifyContent = breakpoint.justifyContent || 'flex-start';
			customSx.flexWrap = breakpoint.flexWrap;
		} else {
			customSx.justifyContent = alignMap[align || 'left'] || alignMap.left;
		}

		if (position && position.includes('absolute')) {
			customSx.height = '100%';
			customSx.width = '100%';
			return { ...baseBoxSx, ...customSx };
		}

		if (margin.top !== undefined) {
			customSx.marginTop = `${margin.top}px`;
		}
		if (margin.bottom !== undefined) {
			customSx.marginBottom = `${margin.bottom}px`;
		}
		if (margin.left !== undefined) {
			customSx.marginLeft = `${margin.left}px`;
		}
		if (margin.right !== undefined) {
			customSx.marginRight = `${margin.right}px`;
		}

		if (padding.top !== undefined) {
			customSx.paddingTop = `${padding.top}px`;
		}
		if (padding.bottom !== undefined) {
			customSx.paddingBottom = `${padding.bottom}px`;
		}
		if (padding.left !== undefined) {
			customSx.paddingLeft = `${padding.left}px`;
		}
		if (padding.right !== undefined) {
			customSx.paddingRight = `${padding.right}px`;
		}

		let heightValue = 'auto';
		if (height) {
			if (heightUnit === 'unit') {
				heightValue = `${height * unit}px`;
			} else if (heightUnit !== 'auto') {
				// Эту ветку держать внизу, чтобы 'auto' не залипало если юнит не назначен
				heightValue = `${height}px`;
			}
		}
		customSx.height = heightValue;

		if (direction !== 'vertical') {
			if (width !== undefined) {
				customSx.width = `${width}${widthUnit === '%' ? '%' : 'px'}`;
			} else {
				customSx.width = 'auto';
				customSx.flexGrow = 1;
			}
		} else {
			customSx.width = 'auto';
		}

		return customSx;
	},
)(({ position, direction, breakpoint }) =>
	['widget@getBoxBreakpointSx', position || 'null', direction, _.size(breakpoint)].join(
		'-',
	),
);

export type TGetBoxSxParams = $ReadOnly<{|
	data: TWidget,
	position: TPositionValue,
	direction: TStackDirection,
	gridspec: TSpecsGrid,
|}>;

// Эта функция возвращает объект, передаваемый в свойство `sx`
export type TGetBoxSxFn = TGetBoxSxParams => TSx;

// Стилизация всех виджетов по спекам, с учётом адаптивности для активных девайсов
export const getBoxSx: TGetBoxSxFn = reSelect<
	TGetBoxSxParams,
	?TWidgetBox,
	TId,
	TPositionValue,
	TStackDirection,
	TSpecsGrid,
	TSx,
>(
	({ data }) => data.box,
	({ data }) => data._id,
	({ position }) => position,
	({ direction }) => direction,
	({ gridspec }) => gridspec,
	(box, _id, position, direction, gridspec) => {
		try {
			const breakpointNames = getActiveBreakpointNames({ gridspec });

			const finalSx = maxCombineSx({
				sxList: _.map(currentDevice => {
					const breakpoint = closestDeviceWithKey(box, {
						currentDevice,
						key: `box-${_id}`,
					});
					const breakpointSx = getBoxBreakpointSx({
						breakpoint,
						position,
						direction,
						unit: gridspec.unit,
					});
					return breakpointSx;
				}, breakpointNames),
				gridspec,
			});

			return _.assign(baseBoxSx, finalSx);
		} catch (e) {
			logger.error(e);
			return (emptyObject: TSx);
		}
	},
)(({ data, position, direction, gridspec }) =>
	['widget@getBoxSx', data._id, gridspec._id, position || 'null', direction].join('-'),
);

type TGetDesignBreakpointSxParams = $ReadOnly<{|
	breakpoint: TDesignBreakpoint,
	unit: number,
	device: TGridBreakpointName,
	effectspec: ?TSpecsEffect,
	widgetspec: ?TSpecsWidget,
	colorspec: ?TSpecsColor,
	isLimited?: ?boolean,
|}>;

// Эта функция возвращает объект, передаваемый в свойство `sx`
type TGetDesignBreakpointSxFn = TGetDesignBreakpointSxParams => TSx;

// Для стилизации текста по спеке
export const getDesignBreakpointSx: TGetDesignBreakpointSxFn = reSelect<
	TGetDesignBreakpointSxParams,
	TDesignBreakpoint,
	number,
	TGridBreakpointName,
	?TSpecsEffect,
	?TSpecsWidget,
	?TSpecsColor,
	?boolean,
	TSx,
>(
	({ breakpoint }) => breakpoint,
	({ unit }) => unit,
	({ device }) => device,
	({ effectspec }) => effectspec,
	({ widgetspec }) => widgetspec,
	({ colorspec }) => colorspec,
	({ isLimited }) => isLimited,
	(breakpoint, unit, device, effectspec, widgetspec, colorspec, isLimited) => {
		try {
			const {
				radius,
				border,
				textDesign,
				height,
				heightUnit,
				textHeight,
				textHeightUnit,
				padding,
				paddingUnit,
				textMargin,
				textMarginUnit,
				iconMargin,
				iconMarginUnit,
				btnIconSize,
				btnIconSizeUnit,
				iconSize,
				iconSizeUnit,
				family,
				weight,
				fontSize,
				spacing,
				lettercase,
			} = breakpoint;
			const baseSx = {};

			// Если указан стиль текста, то его надо будет взять из widgetspec (этим же кодом)
			if (widgetspec && textDesign) {
				const item: ?TDesign =
					widgetspec.text && widgetspec.text.find(t => t._id === textDesign);
				if (item) {
					const textBreakpoint: TDesignBreakpoint = closestDeviceWithKey(
						item.breakpoints,
						{ currentDevice: device, key: `breakpoint-${item._id}` },
					);
					const sx = getDesignBreakpointSx({
						breakpoint: textBreakpoint,
						unit,
						device,
						effectspec,
						widgetspec,
						colorspec,
						isLimited: true,
					});
					Object.assign(baseSx, sx);
				}
			}

			if (height) {
				baseSx.height = getUnit(unit, height, heightUnit);
			}
			if (padding) {
				baseSx.padding = `0 ${getUnit(unit, padding, paddingUnit)}`;
			}

			if (effectspec && radius) {
				const item = effectspec.radii.find(r => r.id === radius);
				if (item) {
					const borderRadius = getRadius(item);
					if (borderRadius) {
						baseSx.borderRadius = borderRadius;
					}
				}
			}

			if (effectspec && border) {
				const item = effectspec.borders.find(b => b.id === border);
				if (item) {
					const borderWidth = getBorder(item);
					if (borderWidth) {
						baseSx.borderWidth = borderWidth;
					}
					baseSx.borderStyle = item.kind;
				}
			}

			if (iconMargin) {
				const iconMarginPx = getUnit(unit, iconMargin, iconMarginUnit);
				Object.assign(baseSx, {
					'svg:first-of-type': {
						marginRight: iconMarginPx,
					},
					'svg:last-child': {
						marginLeft: iconMarginPx,
					},
				});
			}

			if (btnIconSize) {
				// eslint-disable-next-line no-shadow
				const fontSize = getUnit(unit, btnIconSize, btnIconSizeUnit);
				baseSx.svg = { fontSize };
			}

			if (family) {
				const webSafeFont = listForFontsLib.find(
					({ family: fontFamily }) => family === fontFamily,
				);
				if (webSafeFont) {
					baseSx.fontFamily = webSafeFont.name;
				} else {
					baseSx.fontFamily = family;
				}
			}
			if (weight) {
				baseSx.fontWeight = weight;
			}
			if (fontSize) {
				baseSx.fontSize = `${fontSize}px`;
			}
			if (textHeight) {
				baseSx.lineHeight = getUnit(unit, textHeight, textHeightUnit);
			}
			if (spacing) {
				baseSx.letterSpacing = `${spacing}px`;
			}
			if (lettercase) {
				baseSx.textTransform = lettercase;
			}

			if (iconSize) {
				// eslint-disable-next-line no-shadow
				const fontSize = getUnit(unit, iconSize, iconSizeUnit);
				baseSx.svg = { fontSize };
				baseSx.boxSizing = 'content-box';
				baseSx.width = fontSize;
				baseSx.height = fontSize;
				if (padding) {
					baseSx.padding = getUnit(unit, padding, paddingUnit);
				}
			}

			if (isLimited || !colorspec) {
				return baseSx;
			}

			if (textMargin) {
				const unitMargin = getUnit(unit, textMargin, textMarginUnit);
				baseSx['span,svg'] = {
					marginBottom: unitMargin,
				};
				baseSx.marginBottom = unitMargin;
			}

			return _.assign(baseSx, getColorSx({ breakpoint, colorspec }));
		} catch (e) {
			logger.error(e);
			return (emptyObject: TSx);
		}
	},
)(({ breakpoint, unit, device, effectspec, widgetspec, colorspec, isLimited }) =>
	[
		'widget@getDesignBreakpointSx',
		_.size(breakpoint),
		(effectspec && effectspec._id) || 'null',
		(widgetspec && widgetspec._id) || 'null',
		(colorspec && colorspec._id) || 'null',
		unit,
		device,
		(isLimited && 'limited') || 'full',
	].join('-'),
);

export type TGetDesignSxParams = $ReadOnly<{|
	design: TDesign,
	gridspec: TSpecsGrid,
	effectspec: TSpecsEffect,
	widgetspec: TSpecsWidget,
	colorspec: TSpecsColor,
	isLimited?: boolean,
|}>;

// Эта функция возвращает объект, передаваемый в свойство `sx`
export type TGetDesignSxFn = TGetDesignSxParams => TSx;

// Стилизация всех виджетов по спекам, с учётом адаптивности для активных девайсов
export const getDesignSx: TGetDesignSxFn = reSelect<
	TGetDesignSxParams,
	TDesign,
	TSpecsGrid,
	TSpecsEffect,
	TSpecsWidget,
	TSpecsColor,
	?boolean,
	TSx,
>(
	({ design }) => design,
	({ gridspec }) => gridspec,
	({ effectspec }) => effectspec,
	({ widgetspec }) => widgetspec,
	({ colorspec }) => colorspec,
	({ isLimited }) => isLimited,
	(design, gridspec, effectspec, widgetspec, colorspec, isLimited) => {
		try {
			const breakpointNames = getActiveBreakpointNames({ gridspec });

			const finalSx = maxCombineSx({
				sxList: _.map(currentDevice => {
					const breakpoint = closestDeviceWithKey(design.breakpoints, {
						currentDevice,
						key: `breakpoint-${design._id}`,
					});
					const breakpointSx = getDesignBreakpointSx({
						device: currentDevice,
						breakpoint,
						unit: gridspec.unit,
						effectspec,
						widgetspec,
						colorspec,
						isLimited,
					});
					return breakpointSx;
				}, breakpointNames),
				gridspec,
			});

			return finalSx;
		} catch (e) {
			logger.error(e);
			return (emptyObject: TSx);
		}
	},
)(({ design, gridspec, effectspec, widgetspec, colorspec, isLimited }) =>
	[
		'widget@getDesignSx',
		design._id,
		gridspec._id,
		effectspec._id,
		widgetspec._id,
		colorspec._id,
		(isLimited && 'limited') || 'full',
	].join('-'),
);

export type TGetBlockBreakpointSxParams = $ReadOnly<{|
	box: TWidgetBoxBreakpoint,
|}>;

// Эта функция возвращает объект, передаваемый в свойство `sx`
export type TGetBlockBreakpointSxFn = TGetBlockBreakpointSxParams => TSx;

// Для стилизации текста по спеке
export const getBlockBreakpointSx: TGetBlockBreakpointSxFn = reSelect<
	TGetBlockBreakpointSxParams,
	TWidgetBoxBreakpoint,
	TSx,
>(
	({ box }) => box,
	box => {
		try {
			const { padding = { top: 0, bottom: 0 } } = box;
			const customSx = {};

			if (padding.top !== undefined) {
				customSx.paddingTop = `${padding.top}px`;
			}
			if (padding.bottom !== undefined) {
				customSx.paddingBottom = `${padding.bottom}px`;
			}

			return customSx;
		} catch (e) {
			logger.error(e);
			return (emptyObject: TSx);
		}
	},
)(({ box }) => ['widget@getBlockBreakpointSx', _.size(box)].join('-'));

// Эта функция возвращает объект, передаваемый в свойство `sx`
export type TBlockBreakpointInfo = $ReadOnly<{|
	width: number,
	padding: number,
	gutter: number,
|}>;
export type TBlockBreakpointInfoParams = $ReadOnly<{|
	breakpoint: TSpecsGridBreakpoint,
	box: TWidgetBoxBreakpoint,
	unit: number,
|}>;
export type TGetBlockBreakpointInfoFn = TBlockBreakpointInfoParams => TBlockBreakpointInfo;

export const getBlockBreakpointInfo: TGetBlockBreakpointInfoFn = reSelect<
	TBlockBreakpointInfoParams,
	TSpecsGridBreakpoint,
	TWidgetBoxBreakpoint,
	number,
	TBlockBreakpointInfo,
>(
	({ breakpoint }) => breakpoint,
	({ box }) => box,
	({ unit }) => unit,
	(breakpoint, box, unit) => {
		const info = {
			width: -1, // Великолепный план, Уолтер. Просто охеренный
			padding: 0,
			gutter: 0,
		};
		try {
			const { gutterUnit, gutter } = breakpoint;

			if (gutterUnit === 'px') {
				info.gutter = gutter;
			}
			if (gutterUnit === 'unit') {
				info.gutter = gutter * unit;
			}
			if (gutterUnit === '%') {
				info.gutter = -gutter * 0.01;
			}

			const { paddingUnit, padding, container, containerUnit } = breakpoint;

			if (paddingUnit === 'px') {
				info.padding = padding;
			}
			if (paddingUnit === 'unit') {
				info.padding = padding * unit;
			}
			if (paddingUnit === '%') {
				info.padding = -padding * 0.01;
			}

			if (box.fluid) {
				return info;
			}

			if (containerUnit === 'px') {
				info.width = container;
			}
			if (containerUnit === 'unit') {
				info.width = container * unit;
			}
			if (containerUnit === '%') {
				info.width = -container * 0.01;
			}

			return info;
		} catch (e) {
			logger.error(e);
			return info;
		}
	},
)(({ breakpoint, box, unit }) =>
	['widget@getBlockBreakpointInfo', _.size(breakpoint), _.size(box), unit].join('-'),
);

export type TGetBlockSxParams = $ReadOnly<{|
	data: TWidget,
	gridspec: TSpecsGrid,
|}>;

// Эта функция возвращает объект, передаваемый в свойство `sx`
export type TGetBlockSxFn = TGetBlockSxParams => TSx;

// Стилизация всех виджетов по спекам, с учётом адаптивности для активных девайсов
export const getBlockSx: TGetBlockSxFn = reSelect<
	TGetBlockSxParams,
	?TWidgetBox,
	TId,
	TSpecsGrid,
	TSx,
>(
	({ data }) => data.box,
	({ data }) => data._id,
	({ gridspec }) => gridspec,
	(box, _id, gridspec) => {
		try {
			const breakpointNames = getActiveBreakpointNames({ gridspec });

			const finalSx = maxCombineSx({
				sxList: _.map(currentDevice => {
					const boxBreakpoint = closestDeviceWithKey(box, {
						currentDevice,
						key: `box-${_id}`,
					});
					const breakpointSx = getBlockBreakpointSx({
						box: boxBreakpoint,
					});
					return breakpointSx;
				}, breakpointNames),
				gridspec,
			});

			return finalSx;
		} catch (e) {
			logger.error(e);
			return (emptyObject: TSx);
		}
	},
)(({ data, gridspec }) =>
	['widget@getBlockSx', _.size(data.box), data._id, gridspec._id].join('-'),
);

export type TGetColBreakpointSxParams = $ReadOnly<{|
	breakpoint: TSpecsGridBreakpoint,
	box: TWidgetBoxBreakpoint,
	size: TSizesBreakpoint,
	unit: number,
|}>;

// Эта функция возвращает объект, передаваемый в свойство `sx`
export type TGetColBreakpointSxFn = TGetColBreakpointSxParams => TSx;

// Для стилизации текста по спеке
export const getColBreakpointSx: TGetColBreakpointSxFn = reSelect<
	TGetColBreakpointSxParams,
	TSpecsGridBreakpoint,
	TWidgetBoxBreakpoint,
	TSizesBreakpoint,
	number,
	TSx,
>(
	({ breakpoint }) => breakpoint,
	({ box }) => box,
	({ size }) => size,
	({ unit }) => unit,
	(breakpoint, box, size, unit) => {
		try {
			const { gutterUnit, gutter } = breakpoint;

			const customSx = {};

			let px = 0;
			if (gutterUnit === 'px') {
				px = `${gutter / 2}px`;
			}
			if (gutterUnit === 'unit') {
				px = `${(gutter * unit) / 2}px`;
			}
			if (gutterUnit === '%') {
				px = `${gutter / 2}%`;
			}
			customSx.paddingLeft = px;
			customSx.paddingRight = customSx.paddingLeft;

			const { width = 1, margin = {} } = size;

			customSx.width = `${width * 100}%`;
			const { left = 0, right = 0 } = margin;
			customSx.marginLeft = `${left * 100}%`;
			customSx.marginRight = `${right * 100}%`;

			return customSx;
		} catch (e) {
			logger.error(e);
			return (emptyObject: TSx);
		}
	},
)(({ breakpoint, box, size, unit }) =>
	['widget@getColBreakpointSx', _.size(breakpoint), _.size(box), size, unit].join('-'),
);

export type TGetColSxParams = $ReadOnly<{|
	data: TWidget,
	sizes: TSize,
	gridspec: TSpecsGrid,
|}>;

// Эта функция возвращает объект, передаваемый в свойство `sx`
export type TGetColSxFn = TGetColSxParams => TSx;

// Стилизация всех виджетов по спекам, с учётом адаптивности для активных девайсов
export const getColSx: TGetColSxFn = reSelect<
	TGetColSxParams,
	?TWidgetBox,
	TId,
	TSize,
	TSpecsGrid,
	TSx,
>(
	({ data }) => data.box,
	({ data }) => data._id,
	({ sizes }) => sizes,
	({ gridspec }) => gridspec,
	(box, _id, sizes, gridspec) => {
		try {
			const breakpointNames = getActiveBreakpointNames({ gridspec });

			const finalSx = maxCombineSx({
				sxList: _.map(currentDevice => {
					const breakpoint = closestDeviceWithKey(gridspec.breakpoints, {
						currentDevice,
						key: `breakpoint-${gridspec._id}`,
					});
					const boxBreakpoint = closestDeviceWithKey(box, {
						currentDevice,
						key: `box-${_id}`,
					});
					const size = closestDeviceWithKey(sizes, {
						currentDevice,
						key: `sizes-${_id}`,
					});
					const breakpointSx = getColBreakpointSx({
						breakpoint,
						box: boxBreakpoint,
						size,
						unit: gridspec.unit,
					});
					return breakpointSx;
				}, breakpointNames),
				gridspec,
			});

			return finalSx;
		} catch (e) {
			logger.error(e);
			return (emptyObject: TSx);
		}
	},
)(({ data, gridspec, sizes }) =>
	['widget@getColSx', data._id, gridspec._id, _.size(sizes)].join('-'),
);
